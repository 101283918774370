import styles from '@/styles/variables.scss'
import Vue from 'vue'
import Component from 'vue-class-component'

// Define a super class component
@Component
export default class App extends Vue {
    // 样式配置
    styleConfig = styles
}
